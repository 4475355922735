import React, { useState } from 'react'
import { getAuth, updateProfile, updatePassword } from 'firebase/auth';
import { Navigate } from 'react-router-dom';
import Header from './Header'
import { initializeApp } from "firebase/app";
import { getFirestore, updateDoc, doc } from "firebase/firestore";
import { db, auth } from "../firebase-config";
import MenuNavegacion from './MenuNavegacion'
import { collection, onSnapshot, query, where } from "firebase/firestore";

function EditProfile() {
    const usuarios = collection(db, "usuarios");
    const [password, setPassword] = useState('');
    const [editError, setEditError] = useState(false);
    const [editOk, setEditOk] = useState(false);
      
    const [nombre, setNombre] = useState("")
    const [nombreCargado, setNombreCargado] = useState(false);
    const [cargaNombre, setCargaNombre] = useState(false);

    if (!cargaNombre) {
        setCargaNombre(true)
        const q = query(usuarios, where("id", "==", auth.currentUser.uid))
        onSnapshot(q, async (snapshot) => {
            snapshot.docs.forEach((doc) => {
                setNombre(doc.data().nombre);
            });
            setNombreCargado(true)
        })
    }

    const submit = async (e) => {
        e.preventDefault();
        updateProfile(auth.currentUser, {
            displayName: nombre,
        }).then(() => {

        }).catch(() => {
            setEditError(true);
            setEditOk(false)
        });
        if (password.length > 0) {
            updatePassword(auth.currentUser, password).then(() => {
                setEditOk(true);
                setEditError(false)
            }).catch(() => {
                setEditError(true);
                setEditOk(false)
            });
        }
        else setEditOk(true);
        
        const usuario = doc(db, "usuarios", auth.currentUser.uid);
        await updateDoc(usuario, {
            nombre: nombre
        });
    }

    return auth.currentUser ? (
        nombreCargado ? (
        editOk ? (
            <div>
                <header>
                    <Header />
                </header>
                <MenuNavegacion />
                <div className="container-sm contenedor">
                    <div className="alert alert-success mt-3">Su perfil se ha actualizado correctamente.</div>
                    <a className="btn btn-primary" href="/" role="button">Volver</a>
                </div >
            </div>
        ) : editError ? (
                <div>
                    <header>
                        <Header />
                    </header>
                    <MenuNavegacion />
                    <div className="container-sm contenedor">
                        <div className="alert alert-danger">Ha habido un error al actualizar su perfil. Recuerde que la contraseña debe tener 6 caracteres como mínimo.</div>
                        <a className="btn btn-primary" href="/" role="button">Volver</a>
                    </div >
                </div>
            ): (
            <div>
                <header>
                    <Header />
                </header>
                <MenuNavegacion />
                <div className="contenedor">
                    <div className="login2 container-sm">
                        <h2 className="text-center mt-3">Editar perfil</h2>
                        <form>
                        
                            <div className=" align-items-center my-3">
                                <p className="text-center">Nombre</p>
                                <input type="text" className="py-2 w-100" name="name" id="name" placeholder="Nombre" value={nombre} onChange={(ev) => setNombre(ev.target.value)} />

                                <a href="#homeSubmenu4" data-toggle="collapse" aria-expanded="false" className="mt-3 btn btn-outline-primary">
                                    Cambiar contraseña
                                </a>

                                <div className="collapse" id="homeSubmenu4">
                                    <input className="mt-3 py-2 w-100" type="password" placeholder="Contraseña" id="password" onChange={(ev) => setPassword(ev.target.value)} />
                                </div>
                                
                            </div>
                            <div>
                                <a className="btn btn-secondary mx-1 my-3" href="/">Cancelar</a>
                                <button className="btn btn-primary mx-1 my-3" onClick={submit}>Guardar</button>
                            </div>          
                        </form>
                        {editError &&
                            <div className="alert alert-danger">La contraseña debe ser de 6 caracteres o superior.</div>
                        }
                    </div >
                </div>
            </div>
            )) : (<div>
                <header>
                    <Header />
                </header>
                <div className="container-sm py-3">
                    <div className="spinner-container">
                        <div className="loading-spinner"></div>
                    </div>
                </div>
            </div>)) : (
            <Navigate to='/login' />
        );

}

export default EditProfile;