import { initializeApp } from "firebase/app";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyA_TkxfCepKNfRY1z-nojKRsWRASFBHPog",
    authDomain: "studygame-9e564.firebaseapp.com",
    projectId: "studygame-9e564",
    storageBucket: "studygame-9e564.appspot.com",
    messagingSenderId: "294200891768",
    appId: "1:294200891768:web:604a3643589e0f8d99108d",
    measurementId: "G-N4TR9TGCKP"
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
enableIndexedDbPersistence(db)
    .catch((err) => {
        if (err.code === 'failed-precondition') {
            // Probablemente m�ltiples pesta�as abiertas en la aplicaci�n
            console.error("Persistencia fallida: m�ltiples pesta�as abiertas");
        } else if (err.code === 'unimplemented') {
            // El navegador no soporta todas las caracter�sticas requeridas
            console.error("Persistencia no soportada");
        }
    });
export { db, auth, firebaseConfig };