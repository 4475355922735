import React, { useState } from 'react'
import Header from './Header'
import MenuNavegacion from './MenuNavegacion'
import { getAuth } from "firebase/auth";
import { Navigate } from 'react-router-dom';
import { collection, getFirestore, getDocs} from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { db, auth } from "../firebase-config";
import cargarRanking from './CargarRanking'

function Ranking(props) {

    const [rankingCargado, setRankingCargado] = useState(false)
    const [cargaRanking, setCargaRanking] = useState(false)
    const [historicoValores] = useState([])
    const [semanalValores] = useState([])
    const [historicoNombres] = useState([])
    const [semanalNombres] = useState([])
    const [cargaExtra, setCargaExtra] = useState(true)

    if (!cargaRanking) {
        setCargaRanking(true)
        cargarRanking2()      
    }

    async function cargarRanking2() {
        const ranking = collection(db, "ranking");
        const querySnapshot = await getDocs(ranking)
        querySnapshot.forEach(async (doc) => {
            if (doc.id === "ultimaActualizacion") {
                var currentDateTime = new Date();
                const diaSec = 86400
                var diferencia = (currentDateTime.getTime() / 1000) - doc.data().fecha.seconds
                if (diferencia < diaSec) setCargaExtra(false)
                else {
                    await cargarRanking()
                    historicoValores.length = 0
                    semanalValores.length = 0
                    historicoNombres.length = 0
                    semanalNombres.length = 0
                    cargarRanking2()
                }
            }
            if (doc.id === "historicoValores") {
                historicoValores.push(doc.data().p1)
                historicoValores.push(doc.data().p2)
                historicoValores.push(doc.data().p3)
                historicoValores.push(doc.data().p4)
                historicoValores.push(doc.data().p5)
            }
            if (doc.id === "semanalValores") {
                semanalValores.push(doc.data().p1)
                semanalValores.push(doc.data().p2)
                semanalValores.push(doc.data().p3)
                semanalValores.push(doc.data().p4)
                semanalValores.push(doc.data().p5)
            }
            if (doc.id === "historicoNombres") {
                historicoNombres.push(doc.data().p1)
                historicoNombres.push(doc.data().p2)
                historicoNombres.push(doc.data().p3)
                historicoNombres.push(doc.data().p4)
                historicoNombres.push(doc.data().p5)
            }
            if (doc.id === "semanalNombres") {
                semanalNombres.push(doc.data().p1)
                semanalNombres.push(doc.data().p2)
                semanalNombres.push(doc.data().p3)
                semanalNombres.push(doc.data().p4)
                semanalNombres.push(doc.data().p5)
            }
        });
        setRankingCargado(true)
    }

    return auth.currentUser ? (
        <div>
            <header>
                <Header />
            </header>

            <MenuNavegacion />
            <div className="contenedor">
                <h1 className="mx-2 centrado">Ranking</h1>
                {(rankingCargado && !cargaExtra) ? (
                    <div>
                        <div className="centrado">                           
                            <div className="table ranking">
                                <h3>Histórico</h3>
                                <table className="table" >
                                    <thead className="thead - light"><tr><th scope="col">Nombre</th><th scope="col">Puntuación</th></tr ></thead >
                                    <tbody>
                                        {
                                            historicoValores.map((val, index) =>
                                                <tr className="celdaUsuario" translate="no" key={index}><td>{historicoNombres[index]}</td><td>{val}</td></tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>                           
                            <div className="table ranking">
                                <h3>Semanal</h3>
                                <table className="table" >
                                    <thead className="thead - light"><tr><th scope="col">Nombre</th><th scope="col">Puntuación</th></tr ></thead >
                                    <tbody>
                                        {
                                            semanalValores.map((val, index) =>
                                                <tr className="celdaUsuario" translate="no" key={index}><td>{semanalNombres[index]}</td><td>{val}</td></tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className = "spinner-container">
                            <div className = "loading-spinner"></div>
                    </div>)}
            </div>
        </div>
    ) : (
        <Navigate to='/login' />
    );
}

export default Ranking;