import { initializeApp } from 'firebase/app';
import { collection, doc, getFirestore, updateDoc, getDocs, serverTimestamp} from "firebase/firestore";
import { db } from "../firebase-config";

async function cargarRanking() {
    var usuarios = []
    const semanaSec = 604800 //1 semana en segundos
    const usuariosDB = collection(db, "usuarios");
    var datosSemana = []
    var datosTotal = []
    const querySnapshot = await getDocs(usuariosDB)
    querySnapshot.forEach((doc) => {
        usuarios.push(doc.data())
    });
    for (let doc of usuarios) {
        let semanal = 0
        let total = 0
        const contestadasDB = collection(db, "usuarios/" + doc.id + "/contestadas")
        const querySnapshot2 = await getDocs(contestadasDB)
        querySnapshot2.forEach((doc2) => {
            if (doc2.data().correcta) {
                total++
                var currentDateTime = new Date();
                var diferencia = (currentDateTime.getTime() / 1000) - doc2.data().fecha.seconds
                if (diferencia < semanaSec) semanal++
            }
        })
        let user = {
            "nombre": doc.nombre,
            "puntuacion": semanal
        }
        let user2 = {
            "nombre": doc.nombre,
            "puntuacion": total
        }
        datosSemana.push(user)
        datosTotal.push(user2)
    }
    await datosSemana.sort(compare)
    await datosTotal.sort(compare)
    const historicoNombres = doc(db, "ranking", "historicoNombres");
    const historicoValores = doc(db, "ranking", "historicoValores");
    const semanalNombres = doc(db, "ranking", "semanalNombres");
    const semanalValores = doc(db, "ranking", "semanalValores");
    const ultimaActualizacion = doc(db, "ranking", "ultimaActualizacion");
    await updateDoc(historicoNombres, {
        p1: datosTotal[0].nombre,
        p2: datosTotal[1].nombre,
        p3: datosTotal[2].nombre,
        p4: datosTotal[3].nombre,
        p5: datosTotal[4].nombre,
    })
    await updateDoc(semanalNombres, {
        p1: datosSemana[0].nombre,
        p2: datosSemana[1].nombre,
        p3: datosSemana[2].nombre,
        p4: datosSemana[3].nombre,
        p5: datosSemana[4].nombre,
    })
    await updateDoc(historicoValores, {
        p1: datosTotal[0].puntuacion,
        p2: datosTotal[1].puntuacion,
        p3: datosTotal[2].puntuacion,
        p4: datosTotal[3].puntuacion,
        p5: datosTotal[4].puntuacion,
    })
    await updateDoc(semanalValores, {
        p1: datosSemana[0].puntuacion,
        p2: datosSemana[1].puntuacion,
        p3: datosSemana[2].puntuacion,
        p4: datosSemana[3].puntuacion,
        p5: datosSemana[4].puntuacion,
    })
    await updateDoc(ultimaActualizacion, {
        fecha: serverTimestamp()
    })
}

function compare(a, b) {
    return a.puntuacion < b.puntuacion ? 1 : -1
}

export default cargarRanking;