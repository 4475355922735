import React, { useState } from 'react';
import logoC from '../assets/images/cursocot.gif';
import { getAuth } from 'firebase/auth';
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { db, auth } from "../firebase-config";

function Header() {
    const [admin, setAdmin] = useState(false);
    const [adminVerified, setAdminVerified] = useState(false);
    const usuarios = collection(db, "usuarios");

    if (auth.currentUser && !admin && !adminVerified) {     
        const q = query(usuarios, where("id", "==", auth.currentUser.uid))
        onSnapshot(q, async (snapshot) => {
            snapshot.docs.forEach((doc) => {
                if (doc.data().admin === true) setAdmin(true);
            });
            setAdminVerified(true)
        })
    }

    return (
        <div className="container-fluid cabecera">
            <div className=" ">
                <nav className="navbar navbar-expand-xl navbar-light d-flex">
                    <div href="/" className="text-decoration-none d-flex flex-md-row align-items-center">
                        <a className="navbar-brand" href="/"><img alt="" width="120px" height="100%" src={logoC}></img></a>
                        <a className="text-dark text-decoration-none" href="/">
                            <b className="fs-3 texto" translate="no">Study Game</b>
                        </a>
                    </div>
                    {auth.currentUser && adminVerified &&

                        <div className="ml-auto p-2" style={{position: "relative"}}>
                            <button className="navbar-toggler collapsed botoncolapse " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">                               
                            <span className="close vertical-center2">X</span>
                                <span className="navbar-toggler-icon navbar" ></span>
                            </button>                          
                        </div>

                    }
                </nav>
            </div>
        </div>  )
    
}


export default Header;



