import React, { useState } from 'react'
import { signOut, getAuth } from 'firebase/auth';

import { collection, onSnapshot, query, where } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { db, auth } from "../firebase-config";

function MenuNavegacion() {
    const [admin, setAdmin] = useState(false);
    const [adminVerified, setAdminVerified] = useState(false);
    const usuarios = collection(db, "usuarios");
    const logout = async () => {
        await signOut(auth);
    }

    if (auth.currentUser && !admin && !adminVerified) {
        const q = query(usuarios, where("id", "==", auth.currentUser.uid))
        onSnapshot(q, async (snapshot) => {
            snapshot.docs.forEach((doc) => {
                if (doc.data().admin === true) setAdmin(true);
            });
            setAdminVerified(true)
        })
    }

    return (auth.currentUser && adminVerified) ? (
        <div>
            <div id="modal_cerrar_sesion" className="modal" >
                <div className="modal-content login">
                    <h2>¿Seguro que desea cerrar sesión?</h2>
                    <div className="my-3">
                        <button className="btn btn-secondary mx-1" onClick={(ev) => { ev.preventDefault(); document.getElementById('modal_cerrar_sesion').style.display = "none"; }}>Cancelar</button>
                        <button className="btn btn-primary mx-1" onClick={logout}>Cerrar sesión</button>
                    </div>
                </div>       
            </div>

            <nav id="navbarSupportedContent" className="collapse msb d-xl-block sidebar">
                <div className="position-sticky" >
                    <ul className="list-group list-group-flush ">
                        <a href="/" className="list-group-item list-group-item-action ripple botonmenu " aria-current="true">
                            <div className="hijoboton">
                                <i className="fa fa-tasks usericon2 me-2 align-middle" align="center"></i><span className="align-middle texto fs-6 ml-3 texto2" translate="no">Test</span>
                            </div>
                        </a>
                        {/*<a href="/multijugador" className="list-group-item list-group-item-action ripple botonmenu " aria-current="true">*/}
                        {/*    <div className="hijoboton">*/}
                        {/*        <i className="fa fa-users usericon2 me-2 align-middle" align="center"></i><span className="align-middle texto fs-6 ml-3 texto2">Multijugador</span>*/}
                        {/*    </div>*/}
                        {/*</a>*/}
                        <a href="/ranking" className="list-group-item list-group-item-action ripple botonmenu">
                            <div className="hijoboton">
                                <i className="fa fa-trophy usericon2 me-2 align-middle" align="center"></i><span className="align-middle texto fs-6 ml-3 texto2" translate="no">Ranking</span>
                            </div>
                        </a>

                        {admin &&
                        <div>
                            <a href="#adminSubmenu" data-toggle="collapse" aria-expanded="false" className=" list-group-item list-group-item-action ripple botonmenu">
                                <div className="hijoboton">
                                    <i className="fa fa-lock usericon2 me-2 align-middle" align="center"></i><span className="dropdown-toggle align-middle texto fs-6 ml-3 texto2">Menú admin</span>
                                </div>
                            </a>

                            <div className="collapse" id="adminSubmenu">                               
                                <a className="list-group-item list-group-item-action ripple botonmenu" href="/admin_menu/usuarios"><div className="hijoboton"><i className="fa fa-address-book-o ml-3 usericon2 me-2 align-middle" align="center"></i><span className="align-middle texto fs-6 ml-3 texto3">Usuarios</span></div></a>
                                <a className="list-group-item list-group-item-action ripple botonmenu" href="/admin_menu/preguntas"><div className="hijoboton"><i className="fa fa-file-text ml-3 usericon2 me-2 align-middle" align="center"></i><span className="align-middle texto fs-6 ml-3 texto3">Preguntas</span></div></a>
                            </div>
                        </div>
                        }
                        
                    

                        <a href="#perfilSubmenu" data-toggle="collapse" aria-expanded="false" className="list-group-item list-group-item-action ripple botonmenu">
                            <div className="hijoboton">
                                <i className="fa fa-user usericon2 me-2 align-middle" align="center"></i><span className="dropdown-toggle align-middle texto fs-6 ml-3 texto2">Perfil</span>
                            </div>
                        </a>

                        <div className="collapse" id="perfilSubmenu">
                            <a className="list-group-item list-group-item-action ripple botonmenu" href="/edit_profile"><div className="hijoboton"><i className="fa fa-pencil ml-3 usericon2 me-2 align-middle" align="center"></i><span className="align-middle texto fs-6 ml-3 texto3">Editar perfil</span></div></a>
                            <button className="list-group-item list-group-item-action ripple botonmenu" onClick={() => { document.getElementById('modal_cerrar_sesion').style.display = "block"; }}><div className="hijoboton"><i className="fa fa-sign-out ml-3 usericon2 me-2 align-middle" align="center"></i><span className="align-middle texto fs-6 ml-3 texto3">Cerrar sesión</span></div></button>
                        </div>
                    
                    </ul>

                </div>
            </nav>
        </div>
    ) : (
            <div></div>)
}

export default MenuNavegacion;