import React, { useState } from 'react'
import { Navigate, useParams } from 'react-router-dom';
import Header from './Header'
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore, updateDoc, getDocs, setDoc, doc, deleteDoc } from "firebase/firestore";
import { firebaseConfig } from "../firebase-config";
import MenuNavegacion from './MenuNavegacion'
import * as XLSX from 'xlsx/xlsx.mjs';
import * as fs from 'fs';

import { Readable } from 'stream';
import * as cpexcel from 'xlsx/dist/cpexcel.full.mjs';
XLSX.stream.set_readable(Readable);
XLSX.set_fs(fs);
XLSX.set_cptable(cpexcel);

function AdminMenu() {    

    const { seccion }=useParams()
    const auth = getAuth();
    const [admin, setAdmin] = useState(false);
    const [verified, setVerified] = useState(false);
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const app2 = initializeApp(firebaseConfig, 'Secundaria');
    const auth2 = getAuth(app2);
    const usuarios = collection(db, "usuarios");
    const eliminados = collection(db, "eliminados");
    const tema = collection(db, "tema")
    const [users] = useState([])
    const [preguntasTema] = useState([])
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [createUserError, setCreateUserError] = useState(false)
    const [createUserSuccess, setCreateUserSuccess] = useState(false)
    const [usersGet, setUsersGet] = useState(false)
    const [editUserError, setEditUserError] = useState(false);
    const [editUserOk, setEditUserOk] = useState(false);
    const [id, setId] = useState("")
    const [idTemaActual, setIdTemaActual] = useState(null)
    const [removeUserOk, setRemoveUserOk] = useState(false);
    const [removeUserError, setRemoveUserError] = useState(false);
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState("");
    const [preguntasCargadas, setPreguntasCargadas] = useState(false)
    const [temasGet, setTemasGet] = useState(false)
    const [checkEliminado, setCheckEliminado] = useState(true)
    const [selectedFile, setSelectedFile] = useState(null);
    const [preguntasSubidas, setPreguntasSubidas] = useState(false)
    const [errorPreguntasSubidas, setErrorPreguntasSubidas] = useState(false)
    const [preguntaActual, setPreguntaActual]=useState(null)
    const [editPreguntaOk, setEditPreguntaOk] = useState(false)
    const [editPreguntaError, setEditPreguntaError] = useState(false)
    const [removePreguntaOk, setRemovePreguntaOk] = useState(false)
    const [removePreguntaError, setRemovePreguntaError] = useState(false)
    const [cargaUsuarios, setCargaUsuarios] = useState(false)
    const [cargaPreguntas, setCargaPreguntas] = useState(false)
    const [cargaTemas, setCargaTemas] = useState(false)
    const [temas] = useState([])

    const limpiarMensajes = () => {
        setCreateUserError(false);
        setCreateUserSuccess(false)
        setRemoveUserOk(false);
        setRemoveUserError(false)
        setEditUserOk(false);
        setEditUserError(false)
        setEditPreguntaError(false)
        setEditPreguntaOk(false)
        setRemovePreguntaOk(false)
        setRemovePreguntaError(false)
        setPreguntasSubidas(false)
    }

    const submitNuevoUsuario = async (e) => {
        e.preventDefault()
        createUserWithEmailAndPassword(auth2, email, makeid(7))
            .then(async(userCredential) => {
                const user = userCredential.user;
                sendPasswordResetEmail(auth, email)
                await setDoc(doc(db, "usuarios", user.uid), {
                    nombre: name,
                    admin: isAdmin,
                    email: email,
                    id: user.uid,
                    puntuacion:0
                }).then(() => {
                    limpiarMensajes()
                    setCreateUserSuccess(true)
                    setCargaUsuarios(false)
                }).catch(() => {
                    limpiarMensajes()
                    setCreateUserError(true);
                });
                document.getElementById('modal_usuario').style.display = "none";
                setName("")
                setEmail("")
                document.getElementById("nombre").value = ""
                document.getElementById("email").value = ""
            })
            .catch(() => {
                limpiarMensajes()              
                setCheckEliminado(false)              
                let elim=false
                const q3 = query(eliminados, where("email", "==", email))
                onSnapshot(q3, (snapshot) => {
                    snapshot.docs.forEach((val) => {
                        elim=true
                        deleteDoc(doc(db, "eliminados", val.id)).then(() => {
                            setDoc(doc(db, "usuarios", val.id), {
                                nombre: name,
                                admin: isAdmin,
                                email: email,
                                id: val.id,
                                puntuacion: 0
                            }).then(() => {
                                sendPasswordResetEmail(auth, email)
                                setCreateUserError(false);
                                setCreateUserSuccess(true)
                                setCargaUsuarios(false)
                            }).catch(() => {
                                setCreateUserSuccess(false)
                                setCreateUserError(true);
                            });
                        });
                    });
                    setCheckEliminado(true)
                    if (!elim)
                        setCreateUserError(true);
                })
                document.getElementById('modal_usuario').style.display = "none";
                setName("")
                setEmail("")
                document.getElementById("nombre").value = ""
                document.getElementById("email").value = ""
            });
        
    }

    const submitEditarUsuario = async (e) => {
        e.preventDefault();
        const usuario = doc(db, "usuarios", id);
        await updateDoc(usuario, {
            nombre: name,
            admin:isAdmin
        }).then(() => {
            limpiarMensajes()
            setEditUserOk(true);
            setName("")
            setEmail("")
            setCargaUsuarios(false)
        }).catch(() => {
            limpiarMensajes()
            setEditUserError(true);
            setName("")
            setEmail("")
        });
        document.getElementById('modal_editar_usuario').style.display = "none";
    }

    const submitEditarPregunta = async (e) => {
        e.preventDefault();
        const pregunta = doc(db, "tema/" + idTemaActual + "/preguntas", preguntaActual.id)
        await updateDoc(pregunta, {
            enunciado: preguntaActual.enunciado,
            opc1: preguntaActual.opc1,
            opc2: preguntaActual.opc2,
            opc3: preguntaActual.opc3,
            opc4: preguntaActual.opc4,
            opc5: preguntaActual.opc5,
            solucion: preguntaActual.solucion,
            comentario: preguntaActual.comentario
        }).then(() => {
            limpiarMensajes()
            setEditPreguntaOk(true)
            mostrarPreguntas(idTemaActual)
        }).catch(() => {
            limpiarMensajes()
            setEditPreguntaError(true)
            mostrarPreguntas(idTemaActual)
        });
        document.getElementById('modal_editar_pregunta').style.display = "none";
        setCargaPreguntas(false)
    }

    const submitEliminarUsuario = async (e) => {
        e.preventDefault()
        await deleteDoc(doc(db, "usuarios", id))
            .then(() => {
                limpiarMensajes()
                setRemoveUserOk(true);
                setCargaUsuarios(false)
            }).catch((error) => {
                limpiarMensajes()
                setRemoveUserError(true);
        });
        await setDoc(doc(db, "eliminados",id), {
            email: email,
            id: id
        });
        document.getElementById('modal_borrar_usuario').style.display = "none";
    }

    const submitEliminarPregunta = async (e) => {
        e.preventDefault()
        await deleteDoc(doc(db, "tema/" + idTemaActual + "/preguntas", preguntaActual.id))
            .then(() => {
                limpiarMensajes()
                setRemovePreguntaOk(true);
                mostrarPreguntas(idTemaActual)
            }).catch(() => {
                limpiarMensajes()
                setRemovePreguntaError(true);
                mostrarPreguntas(idTemaActual)
            });
        document.getElementById('modal_borrar_pregunta').style.display = "none";
        setCargaPreguntas(false)
    }

    if (auth.currentUser && !verified && !admin) {
        const q = query(usuarios, where("id", "==", auth.currentUser.uid))
        onSnapshot(q, async (snapshot) => {
            snapshot.docs.forEach((doc) => {
                if (doc.data().admin === true) setAdmin(true);
            });
            setVerified(true)
        })      
    }

    if(seccion==="usuarios")
        getUsers()

    async function getUsers() {

        if (admin && !cargaUsuarios) {
            setCargaUsuarios(true)
            setUsersGet(false)
            users.length = 0
            const q2 = query(usuarios, where("id", "!=", 0))
            const querySnapshot = await getDocs(q2)
            querySnapshot.forEach((doc) => {
                users.push(doc.data())
            });
            setUsersGet(true)
        }
    }

    if (seccion === "preguntas")
        getTemas()

    async function getTemas() {
        if (admin && !cargaTemas) {
            setCargaTemas(true)
            temas.length = 0
            const q2 = query(tema, where("nombre", "!=", ""))
            const querySnapshot = await getDocs(q2)
            querySnapshot.forEach((doc) => {
                temas.push(doc.data())
            });
            setTemasGet(true)
        }
    }

    const mostrarPreguntas = async (idTema) => {       
        setCargaPreguntas(true)
        setPreguntasCargadas(false)
        preguntasTema.length = 0
        setIdTemaActual(idTema)
        var coleccion = "tema/" + idTema + "/preguntas"
        const q2 = query(collection(db, coleccion), where("enunciado", "!=", ""))
        const querySnapshot = await getDocs(q2)
        querySnapshot.forEach((doc) => {
            preguntasTema.push(doc.data())
        });
        await preguntasTema.sort(compare)
        setPreguntasCargadas(true)
        setCargaPreguntas(false)
    }

    const editarUsuario = (val) => {
        setId(val.id)
        setName(val.nombre)
        setEmail(val.email)
        setIsAdmin(val.admin)
        document.getElementById('modal_editar_usuario').style.display = "block"
    }

    const eliminarUsuario = (val) => {
        setId(val.id)
        setEmail(val.email)
        document.getElementById('modal_borrar_usuario').style.display = "block"
    }

    const editarPregunta = (val) => {
        setPreguntaActual(val)
        document.getElementById('modal_editar_pregunta').style.display = "block"
    }

    const eliminarPregunta = (val) => {
        setPreguntaActual(val)
        document.getElementById('modal_borrar_pregunta').style.display = "block"
    }

    const ampliarCelda = (id) => {
        document.getElementById(id).style = "white-space:normal"
        document.getElementById("b" + id).style.display = "none"
        document.getElementById("bh" + id).style.display = "block"
    }

    const reducirCelda = (id) => {
        document.getElementById(id).style = "white-space:default"
        document.getElementById("b" + id).style.display = "block"
        document.getElementById("bh" + id).style.display = "none"
    }

    const importarExcel = () => {
        setPreguntasSubidas(false)
        let importadas=0
        if (selectedFile) {
            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                var data = event.target.result;

                var workbook = XLSX.read(data, {
                    type: "binary"
                });
                workbook.SheetNames.forEach(sheet => {
                    let rowObject = XLSX.utils.sheet_to_row_object_array(
                        workbook.Sheets[sheet]
                    );
                    rowObject.forEach(async(val) => {
                        if (val["Id pregunta"] !== "" && val["Id pregunta"] !== null && val["Pregunta"] !== null && val["Categoría"] !== "" && val["Opción A"] !== null && val["Opción B"] !== null && val["Opción C"] !== null && val["Opción D"] !== null && val["Opción E"] !== null && val["Cita a mostrar con la respuesta"] !== null && val["número de tema (1-80)"] !== null) {
                            importadas++
                            var sol = 0
                            var categoria=""
                            switch (val["Categoría"]) {
                                case "Columna":
                                    categoria = "columna"
                                    break;
                                case "Ciencias básicas":
                                    categoria = "basicas"
                                    break;
                                case "Fracturas/Trauma":
                                    categoria = "trauma"
                                    break;
                                case "M Inferior":
                                    categoria = "miembro_inferior"
                                    break;
                                case "M Superior":
                                    categoria = "miembro_superior"
                                    break;
                                case "Infantil":
                                    categoria = "infantil"
                                    break;
                                default:
                                    break;
                            }
                            switch (val["Respuesta correcta"]) {
                                case "A":
                                    sol = 1
                                    break;
                                case "B":
                                    sol = 2
                                    break;
                                case "C":
                                    sol = 3
                                    break;
                                case "D":
                                    sol = 4
                                    break;
                                case "E":
                                    sol = 5
                                    break;
                                default:
                                    break;
                            }
                            await setDoc(doc(db, "tema/" + categoria + "/preguntas", val["número de tema (1-80)"] + "." + val["Id pregunta"]), {
                                id: val["número de tema (1-80)"] + "." + val["Id pregunta"],
                                enunciado: val["Pregunta"],
                                opc1: val["Opción A"],
                                opc2: val["Opción B"],
                                opc3: val["Opción C"],
                                opc4: val["Opción D"],
                                opc5: val["Opción E"],
                                tema: val["número de tema (1-80)"],
                                comentario: val["Cita a mostrar con la respuesta"],
                                solucion: sol
                            })
                        }
                    });                   
                });
                limpiarMensajes()
                if (idTemaActual) mostrarPreguntas(idTemaActual)
                if (importadas !== 0) setPreguntasSubidas(true)
                else setErrorPreguntasSubidas(true)
            };
            fileReader.readAsBinaryString(selectedFile);
        }
        document.getElementById("importar_excel").style.display = "none"
        setCargaPreguntas(false)
    }

    return auth.currentUser ? (
        verified && checkEliminado ? (
            admin ? (
                <div>
                    <header>
                        <Header />
                    </header >
                    <MenuNavegacion />
                    <div id="modal_usuario" className="modal">
                        <div className="modal-content login">
                            <form>
                                <h1>Añadir usuario</h1>
                                <input className="py-2 input my-3" type="email" placeholder="Correo electrónico" id="email" onChange={(ev) => setEmail(ev.target.value)} />
                                <input className="py-2 input mt-3 mb-2" type="text" placeholder="Nombre" id="nombre" onChange={(ev) => setName(ev.target.value)} />
                                <label className="py-2 input my-2" ><input className="mx-2" id="admin" type="checkbox" onChange={(ev) => setIsAdmin(ev.target.checked)} />Admin</label>
                                <div className="alert alert-warning">ATENCIÓN: Al crear un nuevo usuario, se le enviará un correo electrónico para el cambio de contraseña.</div>
                                <div className="my-3">
                                    <button className="btn btn-secondary mx-1" onClick={(ev) => { ev.preventDefault(); document.getElementById('modal_usuario').style.display = "none";}}>Cancelar</button>
                                    <button className="btn btn-primary mx-1" onClick={submitNuevoUsuario}>Crear</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="modal_editar_usuario" className="modal" >
                        <div className="modal-content login">
                            <form>
                                <h2>Editar usuario {email}</h2>
                                <p>Nombre</p>
                                <input className="py-2 input my-3" type="text" placeholder="Nombre" id="nombre2" value={name} onChange={(ev) => setName(ev.target.value)} />
                                {isAdmin &&
                                    <label className="py-2 input my-3"><input className="mx-2" id="admin2" type="checkbox" checked onChange={(ev) => setIsAdmin(ev.target.checked)} />Admin</label>
                                }
                                {!isAdmin &&
                                    <label className="py-2 input my-3"><input className="mx-2" id="admin2" type="checkbox" onChange={(ev) => setIsAdmin(ev.target.checked)} />Admin</label>
                                }
                                <div className="my-3">
                                    <button className="btn btn-secondary mx-1" onClick={(ev) => { ev.preventDefault(); document.getElementById('modal_editar_usuario').style.display = "none"; }}>Cancelar</button>
                                    <button className="btn btn-primary mx-1" onClick={submitEditarUsuario}>Editar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="modal_editar_pregunta" className="modal" >
                        <div className="modal-content login">
                            {preguntaActual &&
                                <form>
                                    <h2>Editar pregunta {preguntaActual.id}</h2>
                                    <p>Enunciado</p>
                                    <textarea className="input mb-2" style={{ height: "200px" }} key={preguntaActual.enunciado} defaultValue={preguntaActual.enunciado} onChange={(ev) => { preguntaActual.enunciado = ev.target.value; }} />
                                    <p>Opción A</p>
                                    <input className="input mb-2" type="text" key={preguntaActual.opc1} defaultValue={preguntaActual.opc1} onChange={(ev) => { preguntaActual.opc1 = ev.target.value; setPreguntaActual(preguntaActual);}} />
                                    <p>Opción B</p>
                                    <input className="input mb-2" type="text" key={preguntaActual.opc2} defaultValue={preguntaActual.opc2} onChange={(ev) => { preguntaActual.opc2 = ev.target.value; }} />
                                    <p>Opción C</p>
                                    <input className="input mb-2" type="text" key={preguntaActual.opc3} defaultValue={preguntaActual.opc3} onChange={(ev) => { preguntaActual.opc3 = ev.target.value; }} />
                                    <p>Opción D</p>
                                    <input className="input mb-2" type="text" key={preguntaActual.opc4} defaultValue={preguntaActual.opc4} onChange={(ev) => { preguntaActual.opc4 = ev.target.value; }} />
                                    <p>Opción E</p>
                                    <input className="input mb-3" type="text" key={preguntaActual.opc5} defaultValue={preguntaActual.opc5} onChange={(ev) => { preguntaActual.opc5 = ev.target.value; }} />
                                    <p>Comentario</p>
                                    <textarea className="input mb-2" style={{ height: "200px" }} key={preguntaActual.comentario} defaultValue={preguntaActual.comentario} onChange={(ev) => { preguntaActual.comentario = ev.target.value; }} />
                                    <br/><label>Solución</label><br/>

                                    <label className="opcion">
                                        <input type="radio" name="opcion" key={preguntaActual.solucion} defaultChecked={preguntaActual.solucion === 1} onClick={() => { preguntaActual.solucion = 1; }} />
                                        <span className="ml-1">Opción A</span>
                                    </label><br />
                                    <label className="opcion">
                                        <input type="radio" name="opcion" key={preguntaActual.solucion} defaultChecked={preguntaActual.solucion === 2} onClick={() => { preguntaActual.solucion =2; }} />
                                        <span className="ml-1">Opción B</span>
                                    </label><br />
                                    <label className="opcion">
                                        <input type="radio" name="opcion" key={preguntaActual.solucion} defaultChecked={preguntaActual.solucion === 3} onClick={() => { preguntaActual.solucion = 3; }} />
                                        <span className="ml-1">Opción C</span>
                                    </label><br />
                                    <label className="opcion">
                                        <input type="radio" name="opcion" key={preguntaActual.solucion} defaultChecked={preguntaActual.solucion === 4} onClick={() => { preguntaActual.solucion = 4; }} />
                                        <span className="ml-1">Opción D</span>
                                    </label><br />
                                    <label className="opcion">
                                        <input type="radio" name="opcion" key={preguntaActual.solucion} defaultChecked={preguntaActual.solucion === 5} onClick={() => { preguntaActual.solucion = 5; }} />
                                        <span className="ml-1">Opción E</span>
                                    </label><br />
                                    <div className="my-3">
                                        <button className="btn btn-secondary mx-1" onClick={(ev) => { ev.preventDefault(); document.getElementById('modal_editar_pregunta').style.display = "none"; }}>Cancelar</button>
                                        <button className="btn btn-primary mx-1" onClick={submitEditarPregunta}>Editar</button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                    <div id="modal_borrar_usuario" className="modal" >
                        <div className="modal-content login">
                            <h2>¿Seguro que desea eliminar el usuario {email}?</h2>
                            <div className="my-3">
                                <button className="btn btn-secondary mx-1" onClick={(ev) => { ev.preventDefault(); document.getElementById('modal_borrar_usuario').style.display = "none"; }}>Cancelar</button>
                                <button className="btn btn-danger mx-1" onClick={submitEliminarUsuario}>Eliminar</button>
                            </div>
                        </div>
                    </div>
                    <div id="modal_borrar_pregunta" className="modal" >
                        {preguntaActual &&
                            <div className="modal-content login">
                                <h2>¿Seguro que desea eliminar la pregunta {preguntaActual.id}?</h2>
                                <div className="my-3">
                                    <button className="btn btn-secondary mx-1" onClick={(ev) => { ev.preventDefault(); document.getElementById('modal_borrar_pregunta').style.display = "none"; }}>Cancelar</button>
                                    <button className="btn btn-danger mx-1" onClick={submitEliminarPregunta}>Eliminar</button>
                                </div>
                            </div>
                        }
                    </div>
                    <div id="importar_excel" className="modal">
                        <div className="modal-content login">
                            <div className="panel-heading"><h4>Sube tu archivo excel</h4></div>
                            <div className="panel-heading"><h4>Formato:</h4></div>
                            <div className="panel-heading"><h6>Deben existir columnas con los siguientes nombres: <br />Id pregunta<br />Pregunta<br />Opción A<br />Opción B<br />Opción C<br />Opción D<br />Opción E<br />Respuesta correcta<br />Cita a mostrar con la respuesta<br />Categoría<br />número de tema (1-80)</h6></div>
                            <div className="mt-3 panel-body">
                                <input type="file" id="fileUpload" accept=".xls,.xlsx" onChange={(ev) => { ev.preventDefault(); setSelectedFile(ev.target.files[0]) }}/><br />
                                <button className="mt-3 btn btn-secondary mx-1" onClick={(ev) => { ev.preventDefault(); document.getElementById('importar_excel').style.display = "none"; }}>Cancelar</button>
                                <button className="mt-3 btn btn-primary mx-1" type="button" id="uploadExcel" onClick={importarExcel}>Importar</button>
                                <div>
                                    <pre id="jsonData"></pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contenedor">
                        {createUserSuccess &&
                            <div className="alert alert-success">El usuario se ha creado correctamente.</div>
                        }
                        {createUserError &&
                            <div className="alert alert-danger">Ha habido un error al crear usuario. Compruebe si el email proporcionado es válido y no tiene ya una cuenta.
                            </div>
                        }
                        {editUserOk &&
                            <div className="alert alert-success">El usuario se ha editado correctamente.</div>
                        }
                        {editUserError &&
                            <div className="alert alert-danger">Ha habido un error al editar usuario.</div>
                        }
                        {removeUserOk &&
                            <div className="alert alert-success">El usuario se ha eliminado correctamente.</div>
                        }
                        {removeUserError &&
                            <div className="alert alert-danger">Ha habido un error al eliminar usuario.</div>
                        }
                        {preguntasSubidas &&
                            <div className="alert alert-success">Las preguntas se han importado correctamente.</div>
                        }
                        {errorPreguntasSubidas &&
                            <div className="alert alert-danger">Error al importar las preguntas. Compruebe que el formato Excel es correcto.</div>
                        }
                        {editPreguntaOk &&
                            <div className="alert alert-success">La pregunta se ha editado correctamente.</div>
                        }
                        {editPreguntaError &&
                            <div className="alert alert-danger">Ha habido un error al editar pregunta.</div>
                        }
                        {removePreguntaOk &&
                            <div className="alert alert-success">La pregunta se ha eliminado correctamente.</div>
                        }
                        {removePreguntaError &&
                            <div className="alert alert-danger">Ha habido un error al eliminar pregunta.</div>
                        }
                        {seccion === "usuarios" &&
                            <div className="tablausuarios">
                                <div className=" d-flex flex-row align-items-center ">                         
                                    <h1>Usuarios</h1>
                                    <button className="btn btn-primary btn-circle mx-4" onClick={() => document.getElementById('modal_usuario').style.display = "block"}>Nuevo usuario</button>                               
                                </div>
                                <div id="tablaUsuarios">
                                    <input className="my-2" type="text" onChange={(ev) => setSearch(ev.target.value)} placeholder="Buscar..."/>
                                    {usersGet?(
                                        <div className="table">
                                                <table className="table" >
                                                <thead className="thead - light"><tr><th scope="col">Nombre</th><th scope="col">Correo</th><th scope="col">Admin</th><th scope="col">Acción</th></tr ></thead >
                                                <tbody >
                                                {
                                                            users.map((val) => 
                                                                (search === " " || (val.nombre !== null && search.toLowerCase() !== null && val.nombre.slice(0, search.length).toLowerCase() === search.toLowerCase()) || (search !== null && val.email.slice(0, search.length).toLowerCase() === search.toLowerCase())) && <tr className="celdaUsuario" key={val.email}><td translate="no">{val.nombre}</td><td>{val.email}</td><td>{val.admin === true ? (<p>Sí</p>) : (<p>No</p>)}</td><td><button className="btn btn-primary btn-circle mr-3" onClick={() => { editarUsuario(val) }}><i className="fa fa-pencil" aria-hidden="true"></i></button><button className="btn btn-danger btn-circle" onClick={() => { eliminarUsuario(val) }}><i className="fa fa-trash" aria-hidden="true"></i></button></td></tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className="container-sm py-3">
                                            <div className="spinner-container">
                                                <div className="loading-spinner"></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        {seccion === "preguntas" &&
                            <div className="tablapreguntas">
                                <div className="d-flex flex-row align-items-center">
                                    <h1>Preguntas</h1>
                                </div>
                                <div id="tablaTemas">
                                <button className="btn btn-primary mt-1" onClick={() => document.getElementById('importar_excel').style.display = "block"}><i className="fa fa-table pr-2" aria-hidden="true"></i>Importar desde Excel</button>
                                    <div className="mt-3"> 
                                        <h3>Categoría:</h3>
                                        {temasGet ? (
                                            <select id="sol" onChange={(ev) => { mostrarPreguntas(ev.target.value); }}>
                                                <option value="" selected disabled hidden>Categoría</option>
                                            {
                                                temas.map((val) =>
                                                    <option key={val.id} value={val.id}>{val.nombre}</option>
                                                    )
                                                }
                                            </select>) : (
                                            <div className="spinner-container">
                                                <div className="loading-spinner"></div>
                                            </div>
                                        )}
                                            {preguntasCargadas ? (
                                                    <div>
                                                        <input className="mt-3 mb-1" type="text" onChange={(ev) => setSearch2(ev.target.value)} placeholder="Filtrar por tema" />
                                                        <table className="table mt-3" >
                                                            <thead className="thead - light"><tr><th scope="col">Ampliar</th><th scope="col" className="columnaid">Id</th><th scope="col">Enunciado</th><th scope="col">Opción A</th><th scope="col">Opción B</th><th scope="col">Opción C</th><th scope="col">Opción D</th><th scope="col">Opción E</th><th scope="col">Respuesta</th><th scope="col">Comentario</th><th className="pr-5" scope="col">Acción</th></tr ></thead >
                                                            <tbody >
                                                                {preguntasTema.map((val) =>
                                                                    (search2 === "" || (val.id !== null && search2 !== null && val.id.substring(0, val.id.indexOf(".")) === search2) ) && <tr id={val.id} key={val.id}><td style={{ width: "65px" }}><button id={"b" + val.id} className="btn btn-primary btn-circle btn-xl botonplus" onClick={() => { ampliarCelda(val.id) }}><i className="fa fa-angle-double-down" aria-hidden="true"></i></button><button id={"bh" + val.id} style={{ display: "none" }} className="btn btn-primary btn-circle btn-xl botonplus" onClick={() => { reducirCelda(val.id) }}><i className="fa fa-angle-double-up" aria-hidden="true"></i></button></td><td className="columnaid">{val.id}</td><td>{val.enunciado}</td><td>{val.opc1}</td><td>{val.opc2}</td><td>{val.opc3}</td><td>{val.opc4}</td><td>{val.opc5}</td><td translate="no" style={{ width: "65px" }}>{val.solucion === 1 && "A"}{val.solucion === 2 && "B"}{val.solucion === 3 && "C"}{val.solucion === 4 && "D"}{val.solucion === 5 && "E"}</td><td>{val.comentario}</td><td id={"2b" + val.id}><button className="btn btn-primary btn-circle mr-3" onClick={() => { editarPregunta(val) }}><i className="fa fa-pencil" aria-hidden="true"></i></button><button className="btn btn-danger btn-circle" onClick={() => { eliminarPregunta(val) }}><i className="fa fa-trash" aria-hidden="true"></i></button></td></tr>
                                                                )}
                                                            </tbody>
                                                        </table>                       
                                                    </div>
                                                ) : (
                                                    cargaPreguntas?(
                                                    <div className="spinner-container">
                                                        <div className="loading-spinner"></div>
                                                    </div>
                                                    ):(<div></div>)
                                                )
                                            }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </div>
            ): (
               <div>
                    <header>
                        <Header />
                    </header >
                    <MenuNavegacion/>
                    <div className="contenedor mr-2 alert alert-danger">
                        No tiene los permisos necesarios para acceder a esa página.
                    </div>
                </div>
                )
        ): (
                <div>
                    <header>
                        <Header />
                    </header >
                    <div className="container-sm py-3">
                        <div className="spinner-container">
                            <div className="loading-spinner"></div>
                        </div>
                    </div>
                </div >
            )
        ) : (
        <Navigate to='/login' />
    );
}

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function compare(a, b) {
    const partesA = a.id.split(".");
    const partesB = b.id.split(".");
    if (parseInt(partesA[0]) < parseInt(partesB[0])) {
        return -1;
    }
    if (parseInt(partesA[0]) > parseInt(partesB[0])) {
        return 1;
    }
    if (parseInt(partesA[1]) < parseInt(partesB[1])) {
        return -1;
    }
    if (parseInt(partesA[1]) > parseInt(partesB[1])) {
        return 1;
    }
    return 0;
}

export default AdminMenu;