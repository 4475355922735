import './assets/css/App.css';
import React, { useState } from 'react';
import 'firebase/auth';
import { getAuth, onAuthStateChanged, deleteUser } from 'firebase/auth';
import Auth from './components/Auth'
import Ranking from './components/Ranking'
//import Multijugador from './components/Multijugador'
import Test from './components/Test'
import EditProfile from './components/EditProfile'
import Header from './components/Header'
import AdminMenu from './components/AdminMenu'
import ResetPassword from './components/ResetPassword'
import { db, auth } from "./firebase-config";
import { doc, collection, onSnapshot, query, where, deleteDoc, getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import { initializeApp } from "firebase/app";

import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";

function App() {
    const [authenticated, setAuthenticated] = useState(false)
    const [loadingAuth, setLoadingAuth] = useState(true)
    const [eliminado, setEliminado] = useState(false);

    const eliminados = collection(db, "eliminados");
    const [cargarEliminado, setCargarEliminado]=useState(false)

    if (!loadingAuth&&authenticated) {
        const q = query(eliminados, where("id", "==", auth.currentUser.uid))
        onSnapshot(q, async (snapshot) => {
            snapshot.docs.forEach((docx) => {              
                deleteDoc(doc(db, "eliminados", auth.currentUser.uid))
                deleteUser(auth.currentUser)
                setEliminado(true)
            });
            setCargarEliminado(true)
        })
    }

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setAuthenticated(true)
        } else {
            setAuthenticated(false)
        }
        setLoadingAuth(false)
    });
    
    return loadingAuth && !cargarEliminado? (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-sm py-3">
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            </div>
        </div>
    ): eliminado ? (
            <div>
                <header>
                    <Header />
                </header >
                <div className="contenedor alert alert-danger">
                   Su cuenta ha sido eliminada por el administrador. Póngase en contacto con el administrador para recuperarla.
                </div>
            </div>
        ):(
            <Router>
                <Routes>
                    <Route path='/' element={<Test />} />
                    <Route path='/login' element={<Auth />} />
                    <Route path='/edit_profile' element={<EditProfile />} />
                    <Route path='/reset_password' element={<ResetPassword />} />
                    <Route path="/admin_menu/:seccion" element={<AdminMenu/>}/>
                    <Route path="/test/:seccion" element={<Test />} />
                    <Route path='/ranking' element={<Ranking />} />
                    {/*<Route path='/multijugador' element={<Multijugador />}/>*/}
                </Routes>
            </Router>
    );
}

export default App;
