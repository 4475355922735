import React, { useState } from 'react'
import 'firebase/auth';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Navigate } from 'react-router-dom';
import Header from './Header'
import { auth } from "../firebase-config";

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailOk, setEmailOk] = useState(false);
    const submit = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setEmailOk(true)
            })
            .catch((error) => {
                setEmailError(true)
            });
    }
    return !auth.currentUser ? (
        emailOk ? (
            <div>
                <header>
                    <Header />
                </header>
                <div className="container-sm contenedor">
                    <p className="alert alert-success"> Se le ha enviado un correo electrónico con el enlace para recuperar su contraseña. </p>
                    <a className="btn btn-primary mx-1 my-3" href="/" role="button">Volver</a>
                </div >
            </div>
            ):(
            <div>
                <header>
                    <Header />
                </header>
                <div className="container-sm login">
                    <h2 className="text-center mt-3">Recuperar contraseña</h2>
                    <form>
                        <p className="text-center">Introduce tu correo electrónico</p>
                        <div className="align-items-center ">
                            <input type="text" className="py-2 w-100" name="email" id="email" placeholder="Correo electrónico" onChange={(ev) => setEmail(ev.target.value)} />
                        </div>
                        <div>
                            <button className="btn btn-primary mx-1 my-3" onClick={submit}>Enviar correo de verificación</button>
                        </div>
                    </form>
                    {emailError &&
                        <div class="alert alert-danger">El correo proporcionado no se corresponde a ninguna cuenta. Si cree que se trata de un error, póngase en contacto con el administrador.</div>
                    }
                </div >
            </div>
            )) : (
            <Navigate to='/' />
            );

}

export default ResetPassword;