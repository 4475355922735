import React, { useState, useEffect } from 'react'
import 'firebase/auth';
import Header from './Header'
import { getAuth } from "firebase/auth";
import { Navigate, useParams } from 'react-router-dom';
import MenuNavegacion from './MenuNavegacion'
import { collection, doc, setDoc, getFirestore, getDocs, serverTimestamp, query, getCountFromServer, where, enableIndexedDbPersistence } from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { db, auth } from "../firebase-config";
import cargarRanking from './CargarRanking'
function Test(props) {

    //Diferencia maxima de segundos para que se considere contestada 
    const maximo=5256000 //2 meses
    const numPreguntas = 10

    const { seccion } = useParams()     
    const [temas] = useState([])
    const [cargaTemas, setCargaTemas] = useState(false)
    const [preguntasCargadas, setPreguntasCargadas] = useState(false)
    const [cargaEstadisticas, setCargaEstadisticas] = useState(false)
    const [estadisticasCargadas, setEstadisticasCargadas] = useState(false)
    const [cargaPreguntas, setCargaPreguntas] = useState(false) 
    const [testTerminado, setTestTerminado] = useState(false)
    const tema = collection(db, "tema");
    const [preguntas] = useState([])
    const [contestadas] = useState([])
    const [contestadasEnTiempo] = useState([])
    const [numeroPreguntas] = useState({})
    const [porcentajeContestadas] = useState({})
    const [progresoCargado, setProgresoCargado] = useState(false)
    const [preguntasTest] = useState([])
    const [comenzarTest, setComenzarTest] = useState(false)
    const [preguntaActual, setPreguntaActual] = useState(0)
    const [respuestas] = useState([])
    const [nombreSeccion, setNombreSeccion] = useState("")
    const [nota, setNota] = useState(-1)
    const [mediaTest, setMediaTest] = useState(0)
    const [cargaRanking, setCargaRanking] = useState(false)
    const [flagActualizarProgreso, setFlagActualizarProgreso] = useState(false)
    const [flagTestTerminado, setFlagTestTerminado] = useState(false)

    if (!seccion && !cargaTemas && auth.currentUser) {
        getTemas()
    }

    if (flagActualizarProgreso) {
        actualizarProgreso()
    }
    if (flagTestTerminado) {
        asyncTerminarTest()
    }
    useEffect(() => {
        if (testTerminado && !cargaRanking) {
            setCargaRanking(true);
            setTimeout(() => {
                cargarRanking();
            }, 1000); // Espera de 1 segundo
        }
    }, [testTerminado, cargaRanking]);
    async function asyncTerminarTest() {
        setFlagTestTerminado(false)

        var notaAct = 0
        for (const [index, resp] of respuestas.entries()) {
            var correcta = false
            if (resp === preguntasTest[index].solucion) {
                notaAct++
                correcta = true
                preguntasTest[index].correcta = true
            }
            await setDoc(doc(db, "usuarios/" + auth.currentUser.uid + "/contestadas", preguntasTest[index].id), {
                id: preguntasTest[index].id,
                fecha: serverTimestamp(),
                tema: seccion,
                correcta: correcta
            });
        }
        setNota(notaAct)
        var fecha;
        fecha = new Date();
        setDoc(doc(db, "usuarios/" + auth.currentUser.uid + "/test", fecha.toGMTString()), {
            fecha: serverTimestamp(),
            tema: seccion,
            nota: notaAct,
            porcentaje: (notaAct / numPreguntas) * 100
        });
        setFlagActualizarProgreso(true)
    }
    const terminarTest = (e) => {
        e.preventDefault();
        setTestTerminado(true)
        setProgresoCargado(false)
        setFlagTestTerminado(true)
               
    }

    async function getTemas() {
        setCargaTemas(true)
        if (temas.length === 0) {
            const querySnapshot = await getDocs(tema)
            querySnapshot.forEach((doc) => {
                temas.push(doc.data())
            });
        }

        const progresoDB = collection(db, "usuarios/" + auth.currentUser.uid + "/progreso")
        const consultaProgreso = await getDocs(progresoDB)
        var currentDateTime = new Date();
        const diaSec = 86400
        if (consultaProgreso.size === 0 || (currentDateTime.getTime() / 1000) - consultaProgreso.docs.find(doc => doc.id === 'ultimaActualizacion').data().fecha.seconds > diaSec) {
            setFlagActualizarProgreso(true)
        }
        else {
            consultaProgreso.forEach((doc) => {
                if (doc.data().id !== "ultimaActualizacion") {
                    porcentajeContestadas[doc.data().id] = doc.data().porcentaje
                }
            })
            setProgresoCargado(true)
        }
        
    }

    async function actualizarProgreso() {
        setFlagActualizarProgreso(false)
        if (contestadasEnTiempo.length === 0) {
            const contestadasDB = collection(db, "usuarios/" + auth.currentUser.uid + "/contestadas")
            const querySnapshot2 = await getDocs(contestadasDB)
            querySnapshot2.forEach((doc) => {
                contestadas.push(doc.data())
                var currentDateTime = new Date();
                var diferencia = (currentDateTime.getTime() / 1000) - doc.data().fecha.seconds
                if (diferencia < maximo) {
                    contestadasEnTiempo.push(doc.data())
                }
            });
        }
        else {           
            for (var i = 0; i < respuestas.length; i++) {
                for (var j = 0; j < contestadasEnTiempo.length; j++) {
                    if (contestadasEnTiempo[j].id === preguntasTest[i].id) {
                        contestadasEnTiempo[j].correcta = preguntasTest[i].correcta 
                        contestadasEnTiempo[j].fecha = serverTimestamp()
                    }
                }
            }
        }
        const progresoDBUltimaAc = collection(db, "usuarios/" + auth.currentUser.uid + "/progreso")
        const progresoDocRef = doc(progresoDBUltimaAc, 'ultimaActualizacion');
        await setDoc(progresoDocRef, {
            id: "ultimaActualizacion",
            fecha: serverTimestamp()
        })
        if (temas.length === 0) {
            const querySnapshot = await getDocs(tema)
            querySnapshot.forEach((doc) => {
                temas.push(doc.data())
            });
        }
        if (!seccion) {
            console.log("entro sin atajo")
            for (const val of temas) {
                const preguntasDB = collection(db, "tema/" + val.id + "/preguntas");
                const consulta = query(preguntasDB)
                await getCountFromServer(consulta).then((snapshot) => {
                    numeroPreguntas[val.id] = snapshot.data().count
                }) 
                if (Object.keys(numeroPreguntas).length === temas.length) {
                    for (const val3 of temas) {
                        let total = 0
                        contestadasEnTiempo.forEach((val2) => {
                            if (val2.tema === val3.id && val2.correcta) {
                                total++
                            }
                        })
                        let progreso = total / numeroPreguntas[val3.id]
                        porcentajeContestadas[val3.id] = Math.round(progreso * 100);
                        const progresoDBTema = collection(db, "usuarios/" + auth.currentUser.uid + "/progreso")
                        const progresoDocRefTema = doc(progresoDBTema, val3.id);
                        await setDoc(progresoDocRefTema, {
                            id: val3.id,
                            porcentaje: porcentajeContestadas[val3.id]
                        })
                    }
                    setProgresoCargado(true)

                }
            }
        }
        else {
            console.log("entro por atajo")
            const preguntasDB = collection(db, "tema/" + seccion + "/preguntas");
            
            const consulta = query(preguntasDB)//un count aqui
            await getCountFromServer(consulta).then((snapshot) => {
                numeroPreguntas[seccion] = snapshot.data().count
            })             
            var total = 0
            contestadasEnTiempo.forEach((val2) => {
                if (val2.tema === seccion && val2.correcta) {
                    total++
                }
            })
            var progreso = total / numeroPreguntas[seccion]
            porcentajeContestadas[seccion] = Math.round(progreso * 100);
            const progresoDBTema = collection(db, "usuarios/" + auth.currentUser.uid + "/progreso")
            const progresoDocRefTema = doc(progresoDBTema, seccion);
            await setDoc(progresoDocRefTema, {
                id: seccion,
                porcentaje: porcentajeContestadas[seccion]
            })
                
            setProgresoCargado(true)

            
        }

    }


    if (seccion && comenzarTest && !cargaPreguntas)
        getPreguntas()

    if (seccion && !comenzarTest && !cargaEstadisticas)
        getEstadisticas()

    async function getPreguntas() {        
        setCargaPreguntas(true)        
        
        shuffle(preguntas)

        var contestadasTema = []
        contestadasEnTiempo.forEach((val2) => {
            if (val2.correcta && val2.tema === seccion) contestadasTema.push(val2)
        })

        var index=0

        if (preguntas.length <= contestadasTema.length && preguntas.length > 0) {
            for (let step = 0; step < numPreguntas; step++) {
                preguntasTest.push(preguntas[step])
            }
        }
        else {
            while (preguntasTest.length !== numPreguntas && index !== preguntas.length) {
                var encontrado = false
                for (const val2 of contestadasTema) {
                    if (val2.id === preguntas[index].id) {
                        encontrado = true
                        break
                    }
                }
                if (!encontrado) {
                    preguntasTest.push(preguntas[index])
                }
                index++
            }
            if (preguntasTest.length < numPreguntas && preguntas.length > 0 ) {
                index=0
                while (preguntasTest.length !== numPreguntas) {
                    for (const val2 of preguntas) {
                        if (val2.id === contestadasTema[index].id) {
                            preguntasTest.push(val2)
                            break
                        }
                    }
                    index++
                }
            }
        }

        for (let step = 0; step < 10; step++) {
            respuestas.push(0)
        }

        setPreguntasCargadas(true)
    }

    async function getEstadisticas() {
        setCargaEstadisticas(true)
        
        const querySnapshot = await getDocs(tema)
        querySnapshot.forEach((doc) => {
            if (doc.data().id === seccion)
                setNombreSeccion(doc.data().nombre)
        });
        
        const contestadasDB = collection(db, "usuarios/" + auth.currentUser.uid + "/contestadas")
        const contestadasDBQuery = query(contestadasDB, where("tema", "==", seccion))
        const querySnapshot2 = await getDocs(contestadasDBQuery)
        querySnapshot2.forEach((doc) => {
            contestadas.push(doc.data())
            var currentDateTime = new Date();
            var diferencia = (currentDateTime.getTime() / 1000) - doc.data().fecha.seconds
            if (diferencia < maximo) contestadasEnTiempo.push(doc.data())
        });

        const preguntasDB = collection(db, "tema/" + seccion + "/preguntas");
        const consulta = await getDocs(preguntasDB)
        consulta.forEach((doc) => {
            preguntas.push(doc.data())
        })
        numeroPreguntas[seccion] = consulta.size
        var total = 0
        contestadasEnTiempo.forEach((val2) => {
            if (val2.tema === seccion && val2.correcta) total++
        })
        var progreso = total / numeroPreguntas[seccion]
        porcentajeContestadas[seccion] = Math.round(progreso * 100);

        const notasDB = collection(db, "usuarios/" + auth.currentUser.uid + "/test")
        const notasDBQuery = query(notasDB, where("tema", "==", seccion))
        const querySnapshot3 = await getDocs(notasDBQuery)
        var media = 0
        total = 0
        querySnapshot3.forEach((doc) => {
            var currentDateTime = new Date();
            var diferencia = (currentDateTime.getTime() / 1000) - doc.data().fecha.seconds
            if (doc.data().tema === seccion && diferencia < maximo) {
                media += doc.data().porcentaje
                total++
            }
        });

        if(total===0) media=0
        else media/=total
        setMediaTest(media)
        setEstadisticasCargadas(true)
    }

    return auth.currentUser ? (
        <div>
            <header>
                <Header />
            </header>

            <MenuNavegacion />

            <div className="contenedor2">
                {!seccion &&
                    <div className="container-sm centrado contenedortemas">
                        <b className="fs-3 texto">Elige un tema</b>
                        {progresoCargado ? (
                            <div className=" " >
                                    {
                                        temas.map((val) =>
                                            <div key={val.nombre} className="contenedortemas2">
                                                <button className="btn btn-primary boton2 mt-5 mb-2 mx-5" onClick={() => { window.location.href="/test/"+val.id }}>{val.nombre}</button>
                                                <div className="progress mx-5">
                                                    <div className="progress-bar" style={{ width: porcentajeContestadas[val.id] + "%" }}>
                                                        <span className="progress-bar-text">{porcentajeContestadas[val.id]}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                            </div>
                        ) : (
                            <div className="spinner-container">
                                <div className="loading-spinner"></div>
                            </div>
                        )
                        }
                    </div>
                }
                {seccion && comenzarTest &&
                    <div>
                    {preguntasCargadas ? (
                        !testTerminado ? (
                            preguntasTest.map((pregunta, index) =>
                            <div key={"pregunta"+index} className="mx-2">
                                {index === preguntaActual &&
                                    <div>
                                        <div className="muestrapregunta">
                                            <form id={"preg" + preguntaActual} className="pregunta pl-1">
                                                <p>{preguntasTest[preguntaActual].enunciado}</p>

                                                <label className="filatest">
                                                    <input type="radio" name="opcion" id={"opc1" + preguntaActual} defaultChecked={respuestas[preguntaActual] === 1} onClick={() => { respuestas[preguntaActual] = 1; }} />
                                                    <span className="ml-1">{preguntasTest[preguntaActual].opc1}</span>
                                                </label>

                                                <label className="filatest">
                                                    <input type="radio" name="opcion" id={"opc2" + preguntaActual} defaultChecked={respuestas[preguntaActual] === 2} onClick={() => { respuestas[preguntaActual] = 2; }} />
                                                    <span className="ml-1">{preguntasTest[preguntaActual].opc2}</span>
                                                </label>

                                                <label className="filatest">
                                                    <input type="radio" name="opcion" id={"opc3" + preguntaActual} defaultChecked={respuestas[preguntaActual] === 3} onClick={() => { respuestas[preguntaActual] = 3; }} />
                                                    <span className="ml-1">{preguntasTest[preguntaActual].opc3}</span>
                                                </label><br />


                                                <label className="filatest">
                                                    <input type="radio" name="opcion" id={"opc4" + preguntaActual} defaultChecked={respuestas[preguntaActual] === 4} onClick={() => { respuestas[preguntaActual] = 4; }} />
                                                    <span className="ml-1">{preguntasTest[preguntaActual].opc4}</span>
                                                </label><br />


                                                <label className="filatest">
                                                    <input type="radio" name="opcion" id={"opc5" + preguntaActual} defaultChecked={respuestas[preguntaActual] === 5} onClick={() => { respuestas[preguntaActual] = 5; }} />
                                                    <span className="ml-1">{preguntasTest[preguntaActual].opc5}</span>
                                                </label><br />

                                            </form>
                                            <div className="botones">
                                                {preguntaActual !== numPreguntas - 1 ? (<button className="btn btn-primary mt-3 float-right" onClick={(e) => { e.preventDefault(); setPreguntaActual(preguntaActual + 1) }}><i className="fa fa-angle-double-right pr-2" aria-hidden="true"></i>Siguiente</button>) : (<button className="btn btn-primary mt-3 float-right" onClick={terminarTest}><i className="fa fa-check pr-2" aria-hidden="true"></i>Terminar</button>)}
                                                {preguntaActual !== 0 && <button className="btn btn-primary mr-3 mt-3 float-right" onClick={(e) => { e.preventDefault(); setPreguntaActual(preguntaActual - 1) }}><i className="fa fa-angle-double-left pr-2" aria-hidden="true"></i>Anterior</button>}
                                            </div>
                                        </div>
                                        <div className="seleccionadorpregunta">
                                            <div className="">
                                                Navegar por el test<br />
                                                {preguntasTest.map((pregunta, index) => index !== preguntaActual ? (
                                                    respuestas[index]===0?(
                                                        <button key={"b1"+index } className="botonpregunta btn btn-outline-primary mt-2 mr-2" onClick={(e) => { setPreguntaActual(index) }}>{index + 1}</button>
                                                    ) : (
                                                        <button key={"b2"+index } className="botonpregunta2 btn btn-outline-primary mt-2 mr-2" onClick={(e) => { setPreguntaActual(index) }}>{index + 1}</button>
                                                            )
                                                    ) : (
                                                    <button key={"b3"+index } className="botonpregunta btn btn-primary mt-2 mr-2" onClick={(e) => { setPreguntaActual(index) }}>{index + 1}</button>
                                                        )
                                                
                                                    )
                                                }                             
                                            </div>
                                            <button className="mt-2 link-like-button" onClick={terminarTest}>Terminar test</button>
                                        </div>
                                    </div>
                                }
                                </div>
                            )
                        ) : progresoCargado?(
                            <div className="">
                                <h1 className="mx-2 centrado">Tu nota: {nota}/{numPreguntas}</h1>
                                {(preguntasTest.map((pregunta, index) =>
                                    <div key={"pregunta" + index} className={respuestas[index] === pregunta.solucion ? ("preguntabien pl-1 mx-2 mb-4") : ("preguntamal pl-1 mx-2 mb-4")}>
                                        <p><b>{pregunta.enunciado}</b></p>
                                        <form>
                                            <fieldset disabled="disabled">
                                                <label>
                                                    <input type="radio" name="opcion" id={"opc1" + index} defaultChecked={respuestas[index] === 1} onClick={() => { respuestas[index] = 1; }} />
                                                    <span className="ml-1">{preguntasTest[index].opc1}</span>
                                                </label><br />
                                                <label>
                                                    <input type="radio" name="opcion" id={"opc2" + index} defaultChecked={respuestas[index] === 2} onClick={() => { respuestas[index] = 2; }} />
                                                    <span className="ml-1">{preguntasTest[index].opc2}</span>
                                                </label><br />
                                                <label>
                                                    <input type="radio" name="opcion" id={"opc3" + index} defaultChecked={respuestas[index] === 3} onClick={() => { respuestas[index] = 3; }} />
                                                    <span className="ml-1">{preguntasTest[index].opc3}</span>
                                                </label><br />
                                                <label>
                                                    <input type="radio" name="opcion" id={"opc4" + index} defaultChecked={respuestas[index] === 4} onClick={() => { respuestas[index] = 4; }} />
                                                    <span className="ml-1">{preguntasTest[index].opc4}</span>
                                                </label><br />
                                                <label>
                                                    <input type="radio" name="opcion" id={"opc5" + index} defaultChecked={respuestas[index] === 5} onClick={() => { respuestas[index] = 5; }} />
                                                    <span className="ml-1">{preguntasTest[index].opc5}</span>
                                                </label><br />
                                            </fieldset>
                                        </form>
                                        <br /><p><b>Respuesta correcta:</b> &nbsp;
                                            {pregunta.solucion === 1 ? (pregunta.opc1) : (pregunta.solucion === 2 ? (pregunta.opc2) : (pregunta.solucion === 3 ? (pregunta.opc3) : (pregunta.solucion === 4 ? (pregunta.opc4) : (pregunta.opc5))))}
                                        </p>
                                        <p><b>Explicación:</b> &nbsp;
                                            {pregunta.comentario}
                                        </p>
                                    </div>
                                ))}
                                <div className="mx-2 mb-2 centrado">
                                    <a className="btn btn-primary" href="/" role="button">Continuar</a>
                                </div>
                                )
                            </div>
                            ) : (<div className="spinner-container">
                                <div className="loading-spinner"></div>
                            </div>)
                    ) : (
                        <div className="spinner-container">
                            <div className="loading-spinner"></div>
                        </div>
                        )
                    }
                    </div>
                }
                {seccion && !comenzarTest &&
                    <div>
                    {estadisticasCargadas ? (
                        <div>
                            <div className="container-sm centrado">
                                <h1 >Tu progreso reciente en {nombreSeccion}</h1>
                                <div className="contenedorstats2 mt-3 mb-4">                                   
                                    <p className="my-2">Has respondido correctamente el {porcentajeContestadas[seccion]}% de las preguntas</p>
                                    <div className="centrado">
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: porcentajeContestadas[seccion] + "%" }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="contenedorstats2">
                                    <p className="my-2">Tu nota media en los test de este tema es {Math.round(mediaTest)/10}/10 </p>
                                    <div className="centrado">
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: mediaTest + "%" }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="centrado">
                                <button className="btn btn-primary" style={{ width: "300px", height: "6y0px" }}onClick={(e) => { setComenzarTest(true) }}><i className="fa fa-file-text pr-2" aria-hidden="true"></i>Realizar test</button>
                            </div>
                        </div>
                    ) : (
                        <div className="spinner-container">
                            <div className="loading-spinner"></div>
                        </div>)}
                    </div>
                }
            </div>
        </div>
    ): (
            <Navigate to = '/login'/>
        );
}
function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex !== 0) {

        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export default Test;