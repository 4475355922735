import React, { useState } from 'react'
import 'firebase/auth';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import Header from './Header'
import { Navigate } from 'react-router-dom';
import { auth } from "../firebase-config";

function Auth() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [logError, setLogError] = useState(false);

    const submit = (e) => {
        e.preventDefault();      
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setLogError(false)
            })
            .catch((error) => {
                document.getElementById("email").value='';
                document.getElementById("password").value = '';
                setLogError(true);
            });               
    }

    return !auth.currentUser ? (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-sm login">
                <form>                   
                    <i className="fa fa-solid fa-lock fa-4x text-primary mt-3"></i>
                    <div className="d-flex flex-column flex-md-row align-items-center my-3 row no-gutters">
                        <i className="col-1 fa fa-solid fa-user fa-2x text-primary"></i>
                        <input className="ml-1 py-2 input" type="email" placeholder="Correo electrónico" id="email" onChange={(ev) => setEmail(ev.target.value)} />
                    </div>
                    <div className="d-flex flex-column flex-md-row align-items-center my-3 row no-gutters">
                        <i className="col-1 fa fa-solid fa-key fa-2x text-primary"></i>
                        <input className="ml-1 py-2 input" type="password" placeholder="Contraseña" id="password" onChange={(ev) => setPassword(ev.target.value)} />
                    </div>
                    <div>
                        <p>¿Has olvidado tu contraseña? <a href="/reset_password">Recuperar</a></p>
                    </div>
                    <div>
                        <button className="btn btn-primary mx-1 mb-3" onClick={submit}>Iniciar sesión</button>
                    </div>
                </form>
                {logError &&
                    <div className="alert alert-danger">Los datos de inicio de sesión no son correctos.</div>
                    }
            </div>
        </div>
    ) : (
        <Navigate to='/' />
    );

}

export default Auth;